<template>

  <div>
        <div >
          <v-sheet rounded dark color="green lighten-2" class="d-flex align-center justify-space-between px-3">
            <v-icon small class="mr-2">mdi-cash-multiple</v-icon>
            <h4>{{ getTimeTotal }} {{ BUSINESS_CURRENCY }}</h4>
          </v-sheet>
        </div>

  </div>

</template>

<script>
export default {
  name: "orderStatistics",
  props : ['orders'],
  computed : {
    getTimeTotal(){
      let total = 0;
      this.orders.forEach((order)=>{
        total +=this.getTotalOrder(order)
      })
      return total
    }
  },
  methods : {
    getTotalOrder(order) {
      let price = 0;
      order.products.forEach((item)=>{
        price += item.quantity * item.price
      })
      return price
    },
  }
}
</script>

<style scoped>

</style>